import core from '@/admin/core';
import { User, useUser } from '@/admin/user';
import { SubscriptionPlan, usePayment } from '@/admin/payment';
import { DisplayDate } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';

interface GetHistoryResponse {
  username: string;
  createDate: number;
  historyType: string;
  productId?: string;
  subscriptionPlanId?: string;
  subscriptionProductId?: string;
  giftId?: string;
  expirationDate?: number;
  isUsed?: boolean;
  isAvailableHistory: boolean;
  price: number;
  quantity: number;
  totalPrice: number;
  invoiceId: string;
  updateType?: string;
  useDate?: number;
}

interface GetHistoriesResponse {
  histories: GetHistoryResponse[];
  nextToken?: string;
}

export interface PostUseHistories {
  endUsername: string;
  historyType: string;
  createDate: number;
  isUsed: boolean;
  giftId?: string;
  productId?: string;
}

export interface HistoryBase {
  createDate: DisplayDate;
  updateType?: string;
  user: User | {};
  subscriptionPlan?: SubscriptionPlan;
  productId?: string;
  totalPrice?: number;
  quantity?: number;
  giftId?: string;
  expirationDate?: DisplayDate;
  isAvailableHistory: boolean;
  isUsed?: boolean;
  useDate?: DisplayDate;
  username?: string;
}

export class History implements HistoryBase {
  createDate: DisplayDate;
  updateType?: string;
  user: User | {};
  subscriptionPlan?: SubscriptionPlan;
  productId?: string;
  totalPrice?: number;
  quantity?: number;
  giftId?: string;
  expirationDate?: DisplayDate;
  isAvailableHistory: boolean;
  isUsed?: boolean;
  useDate?: DisplayDate;
  username?: string;
  constructor(props: GetHistoryResponse, user?: User, plan?: SubscriptionPlan) {
    this.createDate = new DisplayDate(props.createDate);
    this.updateType = props.updateType;
    this.user = user || {};
    this.subscriptionPlan = plan;
    this.productId = props.productId;
    this.expirationDate = new DisplayDate(props.expirationDate);
    this.totalPrice = props.totalPrice;
    this.quantity = props.quantity;
    this.giftId = props.giftId;
    this.isAvailableHistory = props.isAvailableHistory;
    this.isUsed = props.isUsed;
    this.useDate = new DisplayDate(props.useDate);
    this.username = props.username;
  }
  getGiftHistoryStatus(isArchive: boolean) {
    if (isArchive) return '削除済';
    if (this.isAvailableHistory) return '有効期限内';
    return '有効期限切れ';
  }
  getGiftHistoryUseStatus(isUsable: boolean) {
    if (!isUsable) return 'もぎりなし';
    if (this.isUsed) return '使用済';
    if (this.isAvailableHistory) return '未使用';
    return '未使用(期限切れ)';
  }
  getCSVGiftHistory(giftName: string, archiveString: string) {
    return {
      ...this,
      giftName,
      archiveString,
    };
  }
}

export interface PostUseHistories {
  endUsername: string;
  historyType: string;
  createDate: number;
  isUsed: boolean;
  giftId?: string;
  productId?: string;
}
export interface GiftHistoryProps {
  giftId: string;
  targets: {
    endUsername: string;
    quantity: number;
  }[];
}

const getHistories = async (
  historyType: string,
  _nextToken?: string,
  username?: string,
  startDate?: number | null,
  endDate?: number | null
) => {
  const { getUsers } = useUser();
  const { getSubscriptionPlans } = usePayment();

  const _getHistories = async () => {
    if (myAttributes.myRequestPermissions?.histories) {
      return await core.httpClient
        .get(
          `/admin/public/histories?${_nextToken ? `nextToken=${_nextToken}` : ''}&historyType=${historyType}${
            username ? `&username=${username}` : ''
          }${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`
        )
        .then(
          (result) => result,
          () => ({ data: { histories: [], nextToken: '' } })
        );
    } else
      return {
        data: {
          histories: [],
          nextToken: '',
        },
      };
  };

  const [getHistoriesResponse, users, plans] = await Promise.all([_getHistories(), getUsers(), getSubscriptionPlans()]);
  const { histories, nextToken } = getHistoriesResponse.data as GetHistoriesResponse;

  return {
    histories: histories.map((history) => {
      return new History(
        history,
        users.find((user) => user.userId === history.username),
        plans.find((plan) => plan.subscriptionPlanId === history.subscriptionPlanId)
      );
    }),
    nextToken,
  };
};

export const toggleUse = async (props: PostUseHistories) => {
  await core.httpClient.post('/admin/public/histories/use', props);
};

export const useHistory = () => {
  return { getHistories };
};

export const addGift = async (props: GiftHistoryProps) => {
  await core.httpClient.post('/admin/public/histories', props);
};
